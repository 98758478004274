<template>
  <div class="model-box" ref="model-box" v-loading="isLoading">
    <div class="model-box-statistic">
      <div class="model-box-statistic-center">
        <div class="model-box-statistic-center-num">
          {{ statisticData.signBillsPremium }}
        </div>
        <div class="model-box-statistic-center-text">签单保费(万元)</div>
      </div>
      <img
        class="model-box-statistic-ornament"
        src="../../../../assets/images/bigScreen/policy/ornament.png"
        alt=""
        srcset=""
      />
      <div class="model-box-statistic-center">
        <div class="model-box-statistic-center-num">
          {{ statisticData.devicesNum }}
        </div>
        <div class="model-box-statistic-center-text">设备数量(台)</div>
      </div>
    </div>
    <div class="model-box-charts">
      <Chart style="height: 100%" ref="pieBox" :option="pieOptions" />
    </div>
    <div class="model-box-charts">
      <Chart style="height: 100%" ref="lineBox" :option="lineOptions" />
    </div>
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
import { getProjectDetails } from "@/api/BigScreenDisplay";
export default {
  props: {
    year: {
      type: String,
      default: "2023",
    },
    projectTypeId: {
      type: [String,Number],
      default: ''
    }
  },
  watch: {
    year: {
      handler(v) {
        this.init();
      },
      deep: true,
    },
    projectTypeId: {
      handler(v) {
        v !== '' ? this.init() : '';
      },
      deep: true,
    }
  },
  data() {
    return {
      pieOptions: {},
      lineOptions: {},
      statisticData: {
        signBillsPremium: 0,
        devicesNum: 0,
      },
      isLoading: false
    };
  },
  components: { Chart },
  created() {
    // this.init();
  },
  methods: {
    init() {
      this.isLoading = true;
      getProjectDetails({
        type: this.projectTypeId,
        year: this.year,
      }).then((res) => {
        this.isLoading = false;
        this.statisticData = res.data;
        this.setPieOptions(res.data.typeRatioDetails);
        this.setLineOptions(res.data.monthlyDataSheet);
      }).catch(err=>{
        this.isLoading = false;
      });
    },
    getSize(res, type) {
      const clientWidth = this.$refs['model-box'].offsetWidth;
      const clientHeight = this.$refs['model-box'].offsetHeight;
      if (!clientWidth) return;
      const fontSize = 100 * (clientWidth / 1920);
      const fontSize2 = 100 * (clientHeight / 1080);
      return res * (type == 2 ? fontSize2 : fontSize);
    },
    setPieOptions(data = []) {
      const sizeHNum = this.getSize(0.3, 2);
      this.pieOptions = {
        tooltip: {
          show: true,
          confine: true,
          backgroundColor: "rgba(9,103,138,0.8)",
          textStyle: {
            color: "rgba(255,255,255,1)",
          },
          formatter: function (e) {
            return e.name + "：" + e.data.ratio + "%";
          },
          trigger: "item",
          position: "top",
          borderColor: "transparent",
          extraCssText: "0px 0px 8px 0px rgba(0,0,0,0.2);border-radius: 4px;",
        },
        legend: {
          show: false,
        },
        color: [
          "RGBA(4, 186, 207, 1)",
          "RGBA(92, 216, 166, 1)",
          "RGBA(91, 144, 250, 1)",
          "RGBA(110, 200, 235, 1)",
          "RGBA(93, 112, 145, 1)",
          "RGBA(146, 111, 201, 1)",
          "RGBA(232, 105, 73, 1)",
          "RGBA(255, 157, 76, 1)",
          "RGBA(246, 189, 22, 1)",
          "RGBA(255, 152, 197, 1)",
        ],
        grid: {
          top: "6%",
          left: 0,
        },
        series: [
          {
            type: "pie",
            center: ["50%", "50%"],
            radius: ["40%", "66%"],
            // labelLine: {
            //   show: true,
            //   length: 1,
            //   length2: 5,
            //     color: "#ffffff",
            //     borderType: 'dashed'
            // },
            label: {
              show: true,
              color: "#96A2B5",
              // lineHeight: 8,
              minMargin: sizeHNum * 1.2,
              formatter: function (e) {
                return "{name|" + e.name + "}\n{value|" + e.data.ratio + "%}";
              },
              rich: {
                name: {
                  fontSize: sizeHNum * 1.2,
                  color: "#ffffff",
                  padding: [0, 0, 0, 0],
                },
                value: {
                  fontSize: sizeHNum * 1.2,
                  color: "#FFFD37",
                  padding: [sizeHNum * 0.2, 0, 0, 0],
                },
                percent: {
                  fontSize: sizeHNum * 1.6,
                  color: "rgba(255, 255, 255, 0.6)",
                },
              },
            },
            showEmptyCircle: true,
            labelLine: {
              length: sizeHNum * 1,
              length2: sizeHNum * 2.5,
              lineStyle: {
                color: "#ffffff",
                type: "dashed",
              },
            },
            emptyCircleStyle: {
              color: "#ffffff",
            },
            data:
              data.map((v) => {
                return {
                  ...v,
                  name: v.label,
                  value: v.ratio || 0,
                };
              }) || [],
          },
        ],
      };
    },
    setLineOptions(data = {}) {
      const sizeHNum = this.getSize(0.3, 2);
      const xAxisData = [
        "1月",
        "2月",
        "3月",
        "4月",
        "5月",
        "6月",
        "7月",
        "8月",
        "9月",
        "10月",
        "11月",
        "12月",
      ];
      const series = [];
      let legendData = []
      for (const key in data) {
        legendData.push(key)
        series.push({
          name: key,
          data: data[key].map((val) => val.value),
          type: "line",
          symbol: "none",
          color: {
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            type: "linear",
            global: false,
            colorStops: [
              {
                offset: 0,
                color:
                  key == 2021 ? "#DDDC38" : key == 2022 ? "#96F5F8" : key == 2023 ? "#96F5F8" : "RGBA(91, 144, 250, 1)",
              },
            ],
          },
        });
      }
      this.lineOptions = {
        tooltip: {
          trigger: "axis",
          //  formatter: function (params, ticket, callback) {
          //    // console.log(params)
          //    let str = `${ params[0].axisValue }</br>`;
          //    params.forEach((item, idx) => {
          //      str += idx === params.length - 1 ? "" : "<br/>";
          //    });
          //    return str;
          //  }
        },
        grid: {
          top: "16%", // 等价于 y: '16%'
          left: "3%",
          right: "8%",
          bottom: "5%",
          containLabel: true,
        },
        legend: {
          data: legendData,
          textStyle: {
            //图例文字的样式
            color: "#fff",
                  fontSize: sizeHNum * 1.2,
          },
          itemWidth: sizeHNum * 1.2,
          right: "2%",
          top: 0,
        },
        icon: "circle",
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xAxisData,
          axisLine: {
            lineStyle: {
              type: "dashed",
              color: "rgba(13, 151, 235, .3)",
            },
          },
          axisTick: {
            //刻度线
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
                  fontSize: sizeHNum * 1.2,
            },
          },
        },

        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              color: "#575E71",
                  fontSize: sizeHNum * 1.2,
            },
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "rgba(13, 151, 235, .3)",
                  fontSize: sizeHNum * 1.2,
            },
          },
          axisLabel: {
            formatter: function (value) {
              return value;
            },
            textStyle: {
              color: "#fff",
                  fontSize: sizeHNum * 1.2,
              
            },
          },
        },
        series: series,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.model-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 3%;
  &-statistic {
    height: 15%;
    flex: none !important;
    display: flex;
    &-ornament {
      width: 0.18rem;
      height: 100%;
    }
    &-center {
      flex: 1;
      height: 100%;
      background-image: url("../../../../assets/images/bigScreen/policy/statistic-center.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      padding: 0.8%;
      &-num {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        text-align: center;
        font-family: "ZhanKuQingKeHuangYouTi";
        font-size: 0.19rem;
        color: rgba(255, 253, 55, 1);
      }
      &-text {
        font-size: 0.15rem;
        font-weight: 300;
        color: #ffffff;
        text-align: center;
      }
    }
  }
  > div {
    flex: 1;
  }
}
</style>