<template>
  <div id="BigScreenDisplay" ref="BigScreenDisplay" class="data-center">
    <!-- 布局头部 -->
    <div class="header" :class="{'bg1':header1,'bg4':header4}">
      <!-- :class="{'bg1':header1,'bg1':header2,'bg1':header3}" -->
      <div class="path-left">
        <img
          src="../../assets/images/bigScreen/equipment/goHome.png"
          @click="handleGoPath('BigScreenDisplay')"
          class="go-home"
          alt=""
          srcset=""
        />
        <img
          src="../../assets/images/bigScreen/policyNavi.png"
          class="go-home"
          style="width:0.84rem"
          @click="handleGoPath('BigScreenDisplayCooperation')"
          alt=""
          srcset=""
        />
      </div>
      <div class="header_text_weather">
        <i class="el-icon-location"></i>
        <span style="margin-left: 0.5em">{{ weatherArea }}</span>
        <img class="header_weather" :src="weatherImg" alt="" />
        <span style="margin-left: 0.5em">{{ weatherText }}</span>
      </div>
      <span style="flex: 2"></span>
      <span class="time-pane">
        {{ dateText }}
        <span style="margin: 0 0.5em">{{ dayNum }}</span>
        {{ timeStr }}
      </span>
      <div class="path-left">
        <img
          src="../../assets/images/bigScreen/policyCp.png"
          class="go-home"
          style="width:0.84rem"
          @click="handleGoPath('BigScreenDisplayProducts')"
          alt=""
          srcset=""
        />
        <img
          src="../../assets/images/bigScreen/equipment/enlarge.png"
          class="go-home"
          style="margin-left: 0.3rem; margin-right: 0"
          @click="handleClick"
          alt=""
          srcset=""
        />
      </div>
    </div>

    <!-- 布局中间部分 -->
    <div class="MainOuter">
      <div class="flex_1 flex-direction">
        <div class="flex_1 digging-box background-box" :style="{ backgroundImage: showProjects[0] ? `url(${showProjects[0].backgroundImg})` : '' }">
          <div style="height: 7%" class="hold-box"></div>
          <div class="hold-box-center">
            <el-select
              v-model="diggingYear"
              class="popper-style"
              placeholder="请选择"
            >
              <el-option
                v-for="item in timeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <Digging ref="Digging" style="height: 93%" :projectTypeId="showProjects[0] ? showProjects[0].projectTypeId : ''" :year="diggingYear" />
        </div>
        <div class="flex_1 lifting-box background-box" :style="{ backgroundImage: showProjects[1] ? `url(${showProjects[1].backgroundImg})` : '' }">
          <div style="height: 7%"></div>
          <span class="hold-box-center">
            <el-select
              v-model="liftingYear"
              class="popper-style"
              placeholder="请选择"
            >
              <el-option
                v-for="item in timeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </span>
          <Lifting ref="Lifting" style="height: 93%" :projectTypeId="showProjects[1] ? showProjects[1].projectTypeId : ''" :year="liftingYear" />
        </div>
      </div>
      <div class="flex_2 flex-direction">
        <div class="flex_2 heartland-box background-box">
          <span class="hold-box-center">
            <el-select
              v-model="statisticalCenterYear"
              class="popper-style"
              placeholder="请选择"
            >
              <el-option
                v-for="item in timeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </span>
          <StatisticalCenter
            ref="StatisticalCenter"
            :year="statisticalCenterYear"
          />
        </div>
        <div class="flex_1 insurance-box background-box">
          <div style="height: 11%"></div>
          <span class="hold-box-center">
            <el-select
              v-model="insuranceYear"
              class="popper-style"
              placeholder="请选择"
            >
              <el-option label="全部" :value="''"> </el-option>
              <el-option
                v-for="item in timeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </span>
          <Insurance ref="Insurance" :year="insuranceYear" />
        </div>
      </div>
      <div class="flex_1 flex-direction">
        <div class="flex_1 agricultural-box background-box" :style="{ backgroundImage: showProjects[2] ? `url(${showProjects[2].backgroundImg})` : '' }">
          <div style="height: 7%" class="hold-box"></div>
          <span class="hold-box-center">
            <el-select
              v-model="agriculturalYear"
              class="popper-style"
              placeholder="请选择"
            >
              <el-option
                v-for="item in timeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </span>
          <Agricultural
            ref="Agricultural"
            style="height: 93%" :projectTypeId="showProjects[2] ? showProjects[2].projectTypeId : ''"
            :year="agriculturalYear"
          />
        </div>
        <div class="flex_1 other-box background-box" :style="{ backgroundImage: showProjects[3] ? `url(${showProjects[3].backgroundImg})` : '' }">
          <div style="height: 9%" class="hold-box"></div>
          <span class="hold-box-center">
            <el-select
              v-model="otherYear"
              class="popper-style"
              placeholder="请选择"
            >
              <el-option
                v-for="item in timeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </span>
          <Other ref="Other" style="height: 93%" :projectTypeId="showProjects[3] ? showProjects[3].projectTypeId : ''" :year="otherYear" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// --------------------------------------------地址及天气查询--------------------------------
import { weatherInfo, amapGetIp } from "@/api/weather";
// --------------------------------------------模块组件--------------------------------------------
import Agricultural from "@/views/BigScreenDisplay/components/policy/Agricultural";
import Digging from "@/views/BigScreenDisplay/components/policy/Digging";
import Insurance from "@/views/BigScreenDisplay/components/policy/Insurance";
import Lifting from "@/views/BigScreenDisplay/components/policy/Lifting";
import Other from "@/views/BigScreenDisplay/components/policy/Other";
import StatisticalCenter from "@/views/BigScreenDisplay/components/policy/StatisticalCenter";
// --------------------------------------------接口--------------------------------------------
import { getProject } from "@/api/BigScreenDisplay";
// --------------------------------------------工具--------------------------------------------
import dayjs from "dayjs";
import moment from "dayjs";
import { mapMutations } from "vuex";

export default {
  // `name`与路由保持一致
  name: "BigScreenDisplayPolicy",
  components: {
    Agricultural,
    Digging,
    Insurance,
    Lifting,
    Other,
    StatisticalCenter,
  },
  data() {
    // this.intervalId = null;
    return {
      header1: true,
      header2: false,
      header3: false,
      header4: false,
      timeStr: "", // 时间
      timer: null, // 时间定时器
      weatherArea: "",
      weatherImg: "",
      weatherText: "",
      dateText: "",
      dayNum: "",
      timeOptions: [
        {
          value: "2024",
          label: "2024年",
        },
        {
          value: "2023",
          label: "2023年",
        },
        {
          value: "2022",
          label: "2022年",
        },
        {
          value: "2021",
          label: "2021年",
        },
        // {
        //   value: "",
        //   label: "全部",
        // },
      ],
      StatisticalCenterVlue: "2024",

      agriculturalYear: "2024",
      diggingYear: "2024",
      insuranceYear: "",
      liftingYear: "2024",
      otherYear: "2024",
      statisticalCenterYear: "2024",
      allData: {},
      fullscreenchange: null,
      intervalId: null,
      showNum: 4,
      showIndex: 0,
      showProjects: [],
      bdProjects: [],
      intervalModel: null
    };
  },
  created() {
    const { name } = this.$store.state.userInfo;
    if (name == "华北利星行") {
      (this.header1 = false), (this.header2 = true);
    } else if (name == "广东伟信市政") {
      this.header3 = true;
    }
    //  else if (name == "中国石油" || name == "YILI_TECH") {
    //   this.header4 = true;
    // }
  },
  // mounted
  activated() {
    let that = this;
    this.init();
    this.handleGetWeather();
    var datas = dayjs().day();
    var week = ["日", "一", "二", "三", "四", "五", "六"];
    this.dayNum = "周" + week[datas];
    this.dateText = moment().format("YYYY-MM-DD");
    this.timer = setInterval(() => {
      const today = moment(new Date()).format("HH:mm:ss");
      this.timeStr = `${today}`;
    }, 1000);

    // this.intervalId = setInterval(async () => {
    //   await this.init();
    // }, 1000 * 30);
    this.handlePolling();
    // 监听放大页面
    this.fullscreenchange = true;
    addEventListener("fullscreenchange", this.handleFullscreenchange);
    addEventListener("visibilitychange", this.handleVisibilitychange);
  },
  // destroyed
  deactivated() {
    clearInterval(this.timer); //销毁定时器
    // clearInterval(this.intervalId);
    clearTimeout(this.intervalId);
    clearTimeout(this.intervalModel);
    this.intervalModel = null;
    this.intervalId = null;
    this.fullscreenchange = null;
    removeEventListener("fullscreenchange", this.handleFullscreenchange);
    removeEventListener("visibilitychange", this.handleVisibilitychange);
  },

  methods: {
    async init() {
      let list = [
        "Insurance",
        "StatisticalCenter",
      ];
      // let list = [
      //   "Insurance",
      //   "StatisticalCenter",
      //   "Agricultural",
      //   "Digging",
      //   "Lifting",
      //   "Other"
      // ];
      list.map((val) => {
        this.$refs[val].init();
      });
      getProject({
        accountName: this.$store.state.userInfo.name
      }).then(res=>{
        this.bdProjects = res.data.bdProjects;
        this.changeProjects();
        this.intervalModel = setInterval(() => {
          this.changeProjects();
        },2000)
      })
    },
    changeProjects() {
      if(this.showIndex >= this.bdProjects.length) {
        this.showIndex = 0;
      }else if ((this.showIndex + this.showNum) > this.bdProjects.length) {
        this.showProjects = [...JSON.parse(JSON.stringify(this.bdProjects)).splice(this.showIndex,this.bdProjects.length - this.showIndex), ...JSON.parse(JSON.stringify(this.bdProjects)).splice(0,(this.showIndex + this.showNum) + 1 - this.bdProjects.length)]
        this.showIndex = (this.showIndex + this.showNum) - this.bdProjects.length;
        return false;
      }
      this.showProjects = JSON.parse(JSON.stringify(this.bdProjects)).splice(this.showIndex,this.showNum);
      this.showIndex += this.showNum;
    },
    handleFullscreenchange() {
      this.init();
    },
    handleVisibilitychange() {
      if (document.visibilityState == "hidden") {
        clearTimeout(this.intervalId);
        this.intervalId = null;
        clearTimeout(this.intervalModel);
        this.intervalModel = null;
      } else {
        this.init();
        if (!this.intervalId) {
          this.handlePolling();
        }
      }
    },
    handlePolling() {
      this.intervalId = setTimeout(async () => {
        await this.init();
        // this.$refs.ModalMap.mapChange()
        clearTimeout(this.intervalId);
        clearTimeout(this.intervalModel);
        this.intervalModel = null;
        if (this.fullscreenchange) {
          this.handlePolling();
        }
      }, 1000 * 60);
    },
    async handleGetWeather() {
      let cityData = await amapGetIp({
        key: "60f85fa7d1c353b0a2ffb92083e365f0",
      });
      let imgList = [
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/rain1.png", //小雨
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/rain2.png", //大雨
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/rain3.png", // 雷雨
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/cloud.png", //多云
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/snow.png", //雪
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/clear.png", // 晴
      ];
      //  {city: cityData.city}
      weatherInfo({
        key: "60f85fa7d1c353b0a2ffb92083e365f0",
        city: cityData.adcode,
      }).then((res) => {
        let weatherData = res.lives[0];
        // let high = weatherData.high.split(' ')[1];
        // let low = weatherData.low.split(' ')[1];
        this.weatherArea = cityData.city;
        this.weatherText =
          weatherData.temperature + "°" + " " + weatherData.weather;
        if (weatherData.weather.indexOf("雷") > -1) {
          this.weatherImg = imgList[2];
        } else if (weatherData.weather.indexOf("大雨") > -1) {
          this.weatherImg = imgList[1];
        } else if (weatherData.weather.indexOf("雨") > -1) {
          this.weatherImg = imgList[0];
        } else if (weatherData.weather.indexOf("雪") > -1) {
          this.weatherImg = imgList[4];
        } else if (weatherData.weather.indexOf("晴") > -1) {
          this.weatherImg = imgList[5];
        } else if (weatherData.weather.indexOf("云") > -1) {
          this.weatherImg = imgList[3];
        } else {
          this.weatherImg = imgList[3];
        }
      });
    },

    /**
     * 进入全屏
     */
    requestFullscreen() {
      const fullscreenEl = this.getFullScreenEl();

      fullscreenEl.requestFullscreen();
      this.isFullscreen = true;
    },
    /**
     * 退出全屏
     */
    exitFullscreen() {
      document.exitFullscreen();
      this.isFullscreen = false;
    },
    handleClick() {
      this.SetFullscreen();
      // const isFullscreen = !this.isFullscreen;

      // if (isFullscreen) { // 进入全屏
      //   this.requestFullscreen();
      // } else {
      //   // 退出全屏
      //   this.exitFullscreen();
      // }
    },
    handleGoPath(name) {
      this.$router.push({
        path: "/" + name,
      });
    },
    ...mapMutations(["SetFullscreen"]),
  },
};
</script>


<style lang="scss" scoped>
@import "./policyStyle.scss";
@import "./BigScreenPublic.scss";
.bg1 {
  background-image: url("../../assets/images/bigScreen/home/titleBg.png");
}
.bg2 {
  background-image: url("../../assets/images/bigScreen/top3.png");
}
.bg3 {
  background-image: url("../../assets/images/bigScreen/top2.png");
}
.bg4 {
  background-image: url('../../assets/images/bigScreen/zgsyBg.png');
}
</style>
<style lang="scss">
.popper-style {
  width: 68px;
  input {
    color: #ffffff;
    border: 1px solid #0267cb;
    background: rgba(157, 254, 255, 0.2);
    padding-left: 0.04rem;
    padding-right: 0.04rem;
  }
  .el-input__inner {
    height: auto;
    line-height: initial;
    padding: 2px;
    padding-right: 22px;
  }
  .el-input__icon {
    height: 0.38rem;
    line-height: 0.38rem;
  }
  .el-input__suffix {
    right: 0;
  }
}
.data-center {
  .el-loading-mask {
    background-color: rgba(24, 34, 55, 0.8);
  }
}
</style>