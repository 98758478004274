<template>
  <div class="model-box" v-loading="isLoading">
    <div class="kind" ref="kind">
      <div class="kind-center" ref="kind-center">
        <!-- width: imgW+'px' -->
        <img
          :style="{height: '100%'}"
          src="../../../../assets/images/bigScreen/policy/center-circle.png"
          alt=""
          srcset=""
        />
        <div class="kind-center-box">
          <div class="kind-center-num">{{ setPolicyCount.total }}<span>单</span></div>
          <div class="kind-center-text">保单数</div>
        </div>
        <div class="kind-center-surround kind-center-surround1">
          <div class="kind-center-surround-num">{{ setPolicyCount.waJueJi.value || 0 }}<span>单</span></div>
          <div class="kind-center-surround-roportion">{{ setPolicyCount.waJueJi.ratio || 0 }}%</div>
        </div>
        <div class="kind-center-surround kind-center-surround2">
          <div class="kind-center-surround-num">{{ setPolicyCount.nongJi.value || 0 }}<span>单</span></div>
          <div class="kind-center-surround-roportion">{{ setPolicyCount.nongJi.ratio || 0 }}%</div>
        </div>
        <div class="kind-center-surround kind-center-surround3">
          <div class="kind-center-surround-num">{{ setPolicyCount.qiZhongJi.value || 0 }}<span>单</span></div>
          <div class="kind-center-surround-roportion">{{ setPolicyCount.qiZhongJi.ratio || 0 }}%</div>
        </div>
        <div class="kind-center-surround kind-center-surround4">
          <div class="kind-center-surround-num">{{ setPolicyCount.qiTa.value || 0 }}<span>单</span></div>
          <div class="kind-center-surround-roportion">{{ setPolicyCount.qiTa.ratio || 0 }}%</div>
        </div>
      </div>
    </div>
    <div class="statistics">
      <div>
        <div class="statistics-num">{{ setPolicyCount.dateDuelTotal || 0 }}<span>单</span></div>
        <div class="statistics-text">到期数量</div>
      </div>
      <div>
        <div class="statistics-num">{{ setPolicyCount.renewalTotal || 0 }}<span>单</span></div>
        <div class="statistics-text">续保数量</div>
      </div>
      <div>
        <div class="statistics-num">{{ setPolicyCount.renewalRatio || 0 }}%</div>
        <div class="statistics-text">续保率</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPolicyCountDetails } from "@/api/BigScreenDisplay";
export default {
  data() {
    return {
      imgW: 0,
      setPolicyCount: {
        waJueJi:{},
        qiZhongJi:{},
        nongJi:{},
        qiTa:{},
      },
      isLoading: false
    };
  },
  props: {
    year: {
      type: String,
      default: "2023",
    },
  },
  watch: {
    year: {
      handler(v) {
        this.init();
      },
      deep: true,
    }
  },
  created() {
    // this.init();
  },
  methods: {
    init() {
      this.isLoading = true;
      getPolicyCountDetails({year: this.year,}).then(res=>{
      this.isLoading = false;
        this.setPolicyCount = res.data;
      }).catch(err=>{
        this.isLoading = false;
      })
      this.$nextTick(() => {
        let a = this.$refs['kind'].offsetHeight;
        let b = this.$refs['kind'].offsetWidth;
        this.imgW = (a + 20) > b ? b: a;
        console.log(this.imgW);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.model-box {
  height: 100%;
  padding: 1;
  .kind {
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    .kind-center {
      position: relative;
      height: 100%;
      &-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &-num {
        text-align: center;
        font-family: "ZhanKuQingKeHuangYouTi";
        font-size: 0.68rem;
        color: rgba(255, 253, 55, 1);
        transform: translateY(31%);
        span {
          font-size: 0.2rem;
          color: #fffefe;
          margin-left: 4px;
        }
      }
      &-text {
        text-align: center;
        font-size: 0.42rem;
        color: #fffefe;
        // margin-top: 20%;
        transform: translateY(100%);
      }
      &-surround {
        position: absolute;
        display: flex;
        align-items: center;
        height: 0.85rem;
        width: 2.56rem;
        background-size: 100%;
        background-repeat: no-repeat;
        padding-left: 0.78rem;
        padding-right: 0.42rem;
        &-roportion {
          font-family: "ZhanKuQingKeHuangYouTi";
          font-size: 0.24rem;
          color: rgba(255, 253, 55, 1);
        }
        &-num {
          flex: 1;
          text-align: center;
          font-family: "ZhanKuQingKeHuangYouTi";
          font-size: 0.24rem;
          color: rgba(255, 253, 55, 1);
          span {
            font-size: 0.15rem;
            color: #fffefe;
          }
        }
      }
      &-surround1 {
        top: 33.33%;
        right: 76%;
        transform: translateY(-50%);
        background-image: url("../../../../assets/images/bigScreen/policy/center-digging.png");
      }
      &-surround2 {
        top: 33.33%;
        left: 76%;
        transform: translateY(-50%);
        background-image: url("../../../../assets/images/bigScreen/policy/center-agricultural.png");
        padding-left: 1.02rem;
        padding-right: 0.12rem;
      }
      &-surround3 {
        top: 68%;
        right: 76%;
        transform: translateY(-50%);
        background-image: url("../../../../assets/images/bigScreen/policy/center-lifting.png");
      }
      &-surround4 {
        top: 68%;
        left: 76%;
        transform: translateY(-50%);
        background-image: url("../../../../assets/images/bigScreen/policy/center-other.png");
        padding-left: 1.02rem;
        padding-right: 0.12rem;
      }
    }
  }
  .statistics {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 30%;
    >div {
      width: 1.9rem;
      height: 0.94rem;
      background-image: url("../../../../assets/images/bigScreen/policy/center-statistics.png");
      background-size: 100%;
      background-repeat: no-repeat;
    }
    &-num {
      margin-top: 0.2rem;
        text-align: center;
        font-family: "ZhanKuQingKeHuangYouTi";
        font-size: 0.25rem;
        color: rgba(255, 253, 55, 1);
        span {
          font-size: 0.12rem;
          color: #fffefe;
          margin-left: 2px;
        }
    }
    &-text {
      text-align: center;
      color: #fffefe;
      font-size: 0.14rem;
      margin-top: 0.1rem;
    }
  }
}
</style>